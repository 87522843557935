import React, {useEffect, useState} from "react";
import Paper from "@material-ui/core/Paper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelopeOpen} from "@fortawesome/free-solid-svg-icons";
import IconButton from "@material-ui/core/IconButton";
import GlobalTrans, {GlobalTransIntl} from "../../helper/GlobalTrans";
import LoadingDataGrid from "../loadingDataGrid/LoadingDataGrid";
import {format} from "date-fns";
import axios from "axios";
import config from "../../config/config";
import {useStore} from "../../store/useStore";
import Notifications from "../notifications/Notifications";
import {useIntl} from "react-intl";
import {getPinUrl, saveValueInClipboard} from "../../helper/Helper";
import {Link, Snackbar} from "@material-ui/core";
import {Alert} from "react-bootstrap";
import SelectProjectWrapper from "../selectProject/selectProjectWrapper";
import Token from "../../helper/Token";
import ApiFilterBuilder from "../../helper/ApiFilterBuilder";

function PinsInvited() {
    const {state} = useStore();
    const intl = useIntl();

    const [cancelToken] = useState(axios.CancelToken.source());
    const [cachedProject, setCachedProject] = useState(state.project ? state.project['@id'] : '');
    const [timeoutWatcher, setTimeoutWatcher] = React.useState(0);
    const [reload, setReload] = React.useState({0: false});

    // Snackbar
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);

    // Notification
    const [notificationSuccess, setNotificationSuccess] = useState(false);
    const [notificationError, setNotificationError] = useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    const assessmentUrl = ApiFilterBuilder([
            {
                name: 'isNewestVersion',
                value: 1,
            }
        ],
        'procedures'
    );

    const [toolbarItems] = React.useState(Token.hasRole(state.tokenData, 'ROLE_PROJECTADMIN') ?
        [
            {
                type: 'text',
                name: 'token',
                label: GlobalTrans('pin')
            },
            {
                type: 'text',
                name: 'fullName',
                label: GlobalTrans('full_name')
            },
            {
                type: 'text',
                name: 'comment',
                label: GlobalTransIntl('comment', intl)
            }
        ] :
        [
            {
                type: 'text',
                name: 'token',
                label: GlobalTrans('pin')
            },
            {
                type: 'text',
                name: 'fullName',
                label: GlobalTrans('full_name')
            },
            {
                type: 'text',
                name: 'comment',
                label: GlobalTransIntl('comment', intl)
            },
            {
                type: 'select',
                getUrl: assessmentUrl,
                name: 'procedureObject',
                label: GlobalTrans('assessment'),
                filterOperator: 'equals'
            },
        ]
    );

    const onClickToken = (params) => {
        saveValueInClipboard(getPinUrl(params));
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const columns = [
        {
            field: 'token', headerName: GlobalTrans('pin'), width: 200,
            renderCell: (params) => {
                if (params.value) {
                    return <Link component={"button"} onClick={() => onClickToken(params.row)} color={"inherit"}>{params.value}</Link>
                }
            }
        },
        {
            field: 'procedureObject', headerName: GlobalTrans('assessment'), width: 150,
            valueGetter: (params) => params.value.name,
            renderCell: (params) => {
                if (params.value) {
                    return <span title={params.value}>{params.value}</span>;
                } else {
                    return '-';
                }
            }
        },
        {field: 'firstName', headerName: GlobalTrans('first_name'), width: 140},
        {field: 'lastName', headerName: GlobalTrans('last_name'), width: 140},
        {
            field: 'fullName',
            headerName: 'Full name',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 160,
            hide: true,
            valueGetter: (params) =>
                `${params.getValue(params.id, 'firstName') || ''} ${
                    params.getValue(params.id, 'lastName') || ''
                }`,
        },
        {
            field: 'invitedAt',
            headerName: GlobalTrans('invited'),
            type: 'date',
            width: 150,
            valueGetter: (params) => {
                if (params.value) {
                    return format(new Date(params.value), 'dd.MM.yyyy');
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'email', headerName: GlobalTrans('email'), width: 150,
            renderCell: (params) => {
                if (params.value) {
                    return <span title={params.value}>{params.value}</span>;
                } else {
                    return '';
                }
            }
        },
        {
            field: 'comment', headerName: GlobalTrans('comment'), width: 160,
            renderCell: (params) => {
                if (params.value) {
                    return <span title={params.value}>{params.value}</span>;
                } else {
                    return '';
                }
            }
        },
        {
            field: 'threeSixtyRoleLabel', headerName: GlobalTrans('role'), width: 150,
            valueGetter: (params) => {
                if (params.value && params.value.length) {
                    return params.value;
                } else {
                    return '-';
                }
            }
        }
    ];

    useEffect(() => {
        if (Object.keys(state.project).length && (cachedProject && cachedProject !== state.project['@id'])) {
            setCachedProject(state.project['@id']);
            setReload({0: true});
        } else if (Object.keys(state.project).length && !cachedProject) {
            setCachedProject(state.project['@id']);
        }
    }, [cachedProject, state.project]);

    useEffect(() => {
        return () => {
            if (timeoutWatcher) {
                clearTimeout(timeoutWatcher);
            }
        };
    }, [timeoutWatcher]);

    const showSuccess = () => {
        resetNotifications();
        setNotificationSuccess(true);
    }

    const showError = (message = '') => {
        resetNotifications();
        setErrorMessage(message);
        setNotificationError(true);
    }

    const resetNotifications = () => {
        setNotificationSuccess(false);
        setNotificationError(false);
    }

    const resendMail = (props) => {
        resetNotifications();

        axios.get(config.apiUrl + `/pins/${state.locale}/sendPin?id=${props.id}`, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
            .then(res => {
                if (res.data) {
                    showSuccess();
                    setReload({0: true});

                    setTimeoutWatcher(setTimeout(() => {
                        resetNotifications();
                    }, 3000));
                }
            })
            .catch(error => {
                if (error.response.data.detail) {
                    showError(error.response.data.detail);
                } else {
                    showError();
                }
            });
    }

    const columnActions = (props) => {
        return (
            <IconButton onClick={() => resendMail(props)} aria-label={GlobalTrans('title_resend_mail')}
                        title={GlobalTrans('title_resend_mail')}>
                <FontAwesomeIcon icon={faEnvelopeOpen} size={"xs"}/>
            </IconButton>
        )
    }

    const content = (
        <React.Fragment>
            <LoadingDataGrid
                apiUrl={'pins'}
                columns={columns}
                toolbarItems={toolbarItems}
                columnActions={columnActions}
                dataGridFilter={
                    [
                        {name: 'status', value: '/api/pin_statuses/2'},
                        {name: 'project', value: state.project['@id']}
                    ]
                }
                reload={reload}
                deleteAction={!Token.hasRole(state.tokenData, 'ROLE_PROJECTADMIN')}
            />
            <Notifications
                cols={{
                    xs: 12,
                    lg: {
                        span: 6,
                        offset: 3
                    }
                }}
                success={notificationSuccess}
                error={notificationError}
                successMessage={GlobalTransIntl('notification_send_success', intl)}
                errorMessage={errorMessage}
            />
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} variant="success" sx={{width: '100%'}}>
                    {GlobalTransIntl('clipboard_saved', intl)}
                </Alert>
            </Snackbar>
        </React.Fragment>
    );

    return (
        <Paper>
            {
                (
                    (!Object.keys(state.project).length) &&
                    <SelectProjectWrapper id='pins-create' rowClass={'p-5'}/>
                )
                ||
                content
            }
        </Paper>
    );
}

export default PinsInvited;