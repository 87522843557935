import React, {useCallback, useEffect, useRef, useState} from "react";
import Form from "react-bootstrap/cjs/Form";
import Button from "react-bootstrap/cjs/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {useIntl} from "react-intl";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import {Box, CardContent, Grid} from "@material-ui/core";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/cjs/Col";
import {checkMultipleAxios, helperCatchErrors, inputOnlyInteger, selectIconComponent} from "../../helper/Helper";
import GlobalTrans, {GlobalTransIntl} from "../../helper/GlobalTrans";
import Notifications from "../notifications/Notifications";
import Spinner from "../spinner/Spinner";
import axios from "axios";
import config from "../../config/config";
import {useStore} from "../../store/useStore";
import SelectProjectWrapper from "../selectProject/selectProjectWrapper";
import ReceiverForm from "../receiver/ReceiverForm";
import Api from "../../helper/Api";
import IconButton from "@material-ui/core/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faFileImport, faUpload} from "@fortawesome/free-solid-svg-icons";
import pages from "../../config/pages";

function PinsCreate() {
    const {state, dispatch} = useStore();
    const intl = useIntl();
    const receiverRef = useRef();

    const [cancelToken] = useState(axios.CancelToken.source());
    const [mounted, setMounted] = useState(false);

    const [assessment, setAssessment] = useState('');
    const [amount, setAmount] = useState('');
    const [language, setLanguage] = useState('');
    const [normgroup, setNormgroup] = useState('');
    const [comment, setComment] = useState('');
    const [timeoutWatcher, setTimeoutWatcher] = React.useState(0);
    const [showImport, setShowImport] = useState(false);
    const [uploadFile, setUploadFile] = useState('');
    const [checkSendMail, setCheckSendMail] = useState(false);
    const [checkForceLanguage, setCheckForceLanguage] = useState(false);
    const [checkIsDontSortQuestionnaire, setCheckIsDontSortQuestionnaire] = useState(false);
    const [checkIsDummy, setCheckIsDummy] = useState(false);

    const [loadingData, setLoadingData] = useState(true);

    const [languages, setLanguages] = useState([]);
    const [loadingLanguages, setLoadingLanguages] = useState(true);
    const [sendingForm, setSendingForm] = useState(false);

    const [normgroups, setNormgroups] = useState(false);
    const [loadingNormgroups, setLoadingNormgroups] = useState(true);

    const [assessments, setAssessments] = useState(false);
    const [loadingAssessments, setLoadingAssessments] = useState(true);

    // Notifications
    const [notificationSuccess, setNotificationSuccess] = useState(false);
    const [notificationError, setNotificationError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const showError = useCallback((errorMessage = '') => {
        setErrorMessage(errorMessage);

        setNotificationError(true);
        setSendingForm(false);
    }, []);

    const catchErrors = useCallback((error) => {
        helperCatchErrors(showError, intl, error);
        setSendingForm(false);
    }, [intl, showError]);

    const showSuccess = () => {
        setNotificationError(false);
        setNotificationSuccess(true);
    }

    const resetNotifications = () => {
        setNotificationSuccess(false);
        setNotificationError(false);
    };

    const getLanguages = useCallback(() => {
        Api.getLanguages(
            (res) => {
                setLanguages(res.data['hydra:member']);
                setLoadingLanguages(false);
            },
            catchErrors,
            state.token,
            cancelToken.token
        );
    }, [state.token, catchErrors, cancelToken.token]);

    const getAssessments = useCallback(() => {
        Api.getAssessments(
            (res) => {
                setAssessments(res.data['hydra:member']);
                setLoadingAssessments(false);
            },
            catchErrors,
            state.token,
            cancelToken.token
        );
    }, [catchErrors, state.token, cancelToken.token]);

    const getNormgroups = useCallback(() => {
        Api.getApi(
            'normgroups',
            (res) => {
                setNormgroups(res.data['hydra:member']);
                setLoadingNormgroups(false);
            },
            catchErrors,
            state.token,
            cancelToken.token
        );
    }, [catchErrors, state.token, cancelToken.token]);

    const resetForm = () => {
        setAssessment('');
        setLanguage('');
        setNormgroup('');
        setAmount('');
        setComment('');
        setCheckIsDontSortQuestionnaire(false);
        receiverRef.current.reset();
    }

    const sendPin = useCallback((newReceiver = undefined) => {
        if (newReceiver.length && !receiverRef.current.validate()) {
            return;
        }

        let data = {
            procedureObject: assessment,
            normgroup: normgroup || null,
            project: state.project['@id'],
            isForcedLanguage: checkForceLanguage,
            isDontSortQuestionnaire: checkIsDontSortQuestionnaire,
            isDummy: checkIsDummy
        };

        if (language) {
            data.language = language;
        }

        if (amount) {
            data.amount = parseInt(amount);
        }

        if (comment.length) {
            data.comment = comment;
        }

        if (newReceiver.length) {
            data.pinMails = newReceiver;
        }

        axios.post(config.apiUrl + `/pins`, data, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
            .then((res) => {
                if (res.data) {
                    showSuccess();

                    setTimeoutWatcher(setTimeout(() => {
                        resetNotifications();
                    }, 3000));
                    resetForm();
                } else {
                    showError();
                }
            })
            .catch(catchErrors);
    }, [assessment, amount, language, normgroup, state.project, comment, state.token, cancelToken.token, catchErrors, showError, checkForceLanguage, checkIsDontSortQuestionnaire, checkIsDummy]);

    useEffect(() => {
        let checkAllAxios = checkMultipleAxios(
            [
                loadingLanguages,
                loadingAssessments,
                loadingNormgroups
            ],
            [
                {
                    name: 'language',
                    value: languages
                },
                {
                    name: 'assessment',
                    value: assessments
                },
                {
                    name: 'normgroup',
                    value: normgroups
                }
            ],
            intl
        )

        if (checkAllAxios.length) {
            showError(checkAllAxios);
            setLoadingData(false);
        } else if (checkAllAxios === true) {
            setLoadingData(false);
        }
    }, [loadingLanguages, loadingAssessments, loadingNormgroups, languages, assessments, normgroups, intl, showError]);

    useEffect(() => {
        if (!mounted) {
            if (Object.keys(state.project).length) {
                setLoadingData(true);
                setMounted(true);
                getLanguages();
                getAssessments();
                getNormgroups();
            } else {
                setLoadingData(false);
            }

        }
    }, [mounted, getLanguages, getAssessments, getNormgroups, cancelToken, state.project]);

    useEffect(() => {
        return () => {
            cancelToken.cancel();
        }
    }, [cancelToken]);

    useEffect(() => {
        return () => {
            if (timeoutWatcher) {
                clearTimeout(timeoutWatcher);
            }
        };
    }, [timeoutWatcher]);

    const onSubmit = (e) => {
        e.preventDefault();
        resetNotifications();

        receiverRef.current.submit();
    }

    const onSubmitImport = (e) => {
        e.preventDefault();
        resetNotifications();

        const formData = new FormData();

        formData.append('file', uploadFile);
        formData.append('project', state.project?.id);

        if (checkSendMail) {
            formData.append('send_mail', 'true');
        }

        setSendingForm(true);

        axios.post(config.apiUrl + `/pin/import/${state.locale}`, formData, config.axiosConfig(state.token, {
            cancelToken: cancelToken.token,
            headers: {'content-type': 'multipart/form-data'}
        }))
            .then((res) => {
                if (res.data) {
                    showSuccess();
                    setUploadFile('');

                    setTimeoutWatcher(setTimeout(() => {
                        dispatch({type: "setPage", payload: pages.PINS_OPEN});
                    }, 3000));
                    resetForm();
                } else {
                    showError();
                }
            })
            .catch((error) => {
                if (error.response?.data?.message) {
                    showError(error.response.data.message);
                } else {
                    catchErrors(error);
                }
            });
    }

    const CreateForm = () => (
        <Form className={'pin-create--form'} onSubmit={onSubmit}>
            <Row>
                <Col xs={12} md={6}>
                    <Form.Group controlId="formBasicAssessment">
                        <FormControl variant={'outlined'} className={'pin-create--form-control'}
                                     id={'pin-create-assessment'} required>
                            <InputLabel>
                                {GlobalTransIntl('assessment', intl)}
                            </InputLabel>
                            <Select
                                labelId="pin-create--assessment-select"
                                value={assessment}
                                onChange={(event) => {
                                    setAssessment(event.target.value)
                                }}
                                label={GlobalTransIntl('assessment', intl)}
                                IconComponent={selectIconComponent}
                            >
                                {
                                    assessments.map((item) =>
                                        <MenuItem value={item['@id']} key={item.id}
                                                  data-name={item.name}>{item.name}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                    {
                        (assessment && assessments.find(element => element['@id'] === assessment)?.availableNormgroups?.length > 0) &&
                        <Form.Group controlId="formBasicNormgroup">
                            <FormControl variant={'outlined'} className={'pin-create--form-control'}
                                         id={'pin-create-normgroup'} required>
                                <InputLabel>
                                    {GlobalTransIntl('normgroup', intl)}
                                </InputLabel>
                                <Select
                                    id="pin-create--normgroup-select"
                                    value={normgroup}
                                    onChange={(event) => {
                                        setNormgroup(event.target.value)
                                    }}
                                    label={GlobalTransIntl('normgroup', intl)}
                                    IconComponent={selectIconComponent}
                                >
                                    {
                                        assessments.find(element => element['@id'] === assessment).availableNormgroups.map((item) =>
                                            <MenuItem
                                                value={normgroups.find(normgroup => normgroup['@id'] === item)['@id']}
                                                key={normgroups.find(normgroup => normgroup['@id'] === item).id}
                                                data-name={normgroups.find(normgroup => normgroup['@id'] === item).name}
                                            >
                                                {normgroups.find(normgroup => normgroup['@id'] === item).name}
                                            </MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Form.Group>
                    }
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6}>
                    <Form.Group controlId="formBasicLanguage">
                        <FormControl
                            variant={'outlined'}
                            className={'pin-create--form-control'}
                            id={'pin-create-language'}
                        >
                            <InputLabel>
                                {GlobalTransIntl('language', intl)}
                            </InputLabel>
                            <Select
                                id="pin-create--language-select"
                                value={language}
                                onChange={(event) => {
                                    setLanguage(event.target.value)
                                }}
                                label={GlobalTransIntl('language', intl)}
                                IconComponent={selectIconComponent}
                            >
                                <MenuItem value={''}
                                          data-name={GlobalTransIntl('translation_default', intl)}
                                >
                                    {GlobalTransIntl('translation_default', intl)}
                                </MenuItem>
                                {
                                    languages.map((item) =>
                                        <MenuItem
                                            value={item['@id']}
                                            key={item.id}
                                            data-name={item.name}
                                        >
                                            {item.name}
                                        </MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                    <Form.Group controlId="formBasicAmount">
                        <TextField label={GlobalTransIntl('amount', intl)}
                                   id={'pin-create--amount'}
                                   autoComplete={'off'}
                                   variant="outlined"
                                   onChange={(e) => {
                                       inputOnlyInteger(e, setAmount)
                                   }}
                                   value={amount}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <ReceiverForm
                ref={receiverRef}
                languages={languages}
                setErrorMessage={setErrorMessage}
                setNotificationError={setNotificationError}
                submitFunction={sendPin}
            />
            <Row>
                <Col xs={12}>
                    <Form.Group>
                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checkForceLanguage}
                                        onChange={
                                            (e) => {
                                                setCheckForceLanguage(e.target.checked)
                                            }
                                        }
                                        color='primary'
                                    />
                                }
                                label={GlobalTransIntl('force_language', intl)}
                            />
                        </div>
                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checkIsDontSortQuestionnaire}
                                        onChange={
                                            (e) => {
                                                setCheckIsDontSortQuestionnaire(e.target.checked)
                                            }
                                        }
                                        color='primary'
                                    />
                                }
                                label={GlobalTransIntl('dont_sort_questionnaire', intl)}
                            />
                        </div>
                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checkIsDummy}
                                        onChange={
                                            (e) => {
                                                setCheckIsDummy(e.target.checked)
                                            }
                                        }
                                        color='primary'
                                    />
                                }
                                label={GlobalTransIntl('create_dummys', intl)}
                            />
                        </div>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6}>
                    <Form.Group controlId="formBasicComment">
                        <TextField
                            label={GlobalTransIntl('comment', intl)}
                            id={'pin-create--comment'}
                            value={comment}
                            variant="outlined"
                            onChange={(e) => {
                                setComment(e.target.value)
                            }}
                            multiline
                            minRows={4}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Button variant="primary" type="submit" className='pin-create--submit'>
                {GlobalTrans('button_create')}
            </Button>
        </Form>
    );

    const handleUploadFileChange = (event) => {
        setUploadFile(event.target.files[0]);
    }

    const ImportForm = () => (
        <form onSubmit={onSubmitImport}>
            <Box sx={{display: 'none'}}>
                <input
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    id="icon-button-file" type="file" onChange={handleUploadFileChange}/>
            </Box>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <label htmlFor="icon-button-file">
                        <IconButton color="primary" aria-label={GlobalTransIntl('import', intl)} component="span">
                            <FontAwesomeIcon icon={faFileImport}/>
                        </IconButton>
                        {(uploadFile) ? uploadFile.name : ''}
                    </label>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkSendMail}
                                onChange={
                                    (e) => {
                                        setCheckSendMail(e.target.checked)
                                    }
                                }
                                color='primary'
                            />
                        }
                        label={GlobalTransIntl('checkbox_send_mail', intl)}
                    />
                </Grid>
            </Grid>
            <Box mt={2}>
                <Button variant="primary" type="submit" className='pin-create--submit'
                        disabled={!uploadFile || sendingForm}>
                    {GlobalTransIntl('import', intl)}
                </Button>
            </Box>
        </form>
    )

    const getCardContent = () => {
        if (!Object.keys(state.project).length) {
            return <SelectProjectWrapper id='pins-create' rowClass={'p-5'}/>;
        }

        if (loadingData || !languages.length) {
            return null;
        }

        return (
            <CardContent>
                <Box mb={2}>
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <h1>
                                {
                                    (
                                        showImport &&
                                        GlobalTransIntl('import_pin', intl)
                                    )
                                    ||
                                    GlobalTransIntl('create_pin', intl)
                                }
                            </h1>
                        </Grid>
                        <Grid item>
                            {
                                (
                                    showImport &&
                                    <IconButton aria-label={GlobalTransIntl('back', intl)}
                                                title={GlobalTransIntl('back', intl)} color="primary"
                                                onClick={() => setShowImport(false)}>
                                        <FontAwesomeIcon icon={faArrowLeft}/>
                                    </IconButton>
                                )
                                ||
                                <IconButton aria-label={GlobalTransIntl('import', intl)}
                                            title={GlobalTransIntl('import', intl)} color="primary"
                                            onClick={() => setShowImport(true)}>
                                    <FontAwesomeIcon icon={faUpload}/>
                                </IconButton>
                            }
                        </Grid>
                    </Grid>
                </Box>
                {
                    (
                        showImport &&
                        ImportForm()
                    )
                    ||
                    CreateForm()
                }
            </CardContent>
        );
    }

    return (
        <Paper>
            <Spinner show={loadingData} rowClass={'p-5'}/>
            <Box pt={2} pr={2} pl={2}>
                <Notifications
                    success={notificationSuccess}
                    error={notificationError}
                    errorMessage={errorMessage}
                />
            </Box>
            {
                getCardContent()
            }
        </Paper>
    );
}

export default PinsCreate;