import React, {useEffect, useState} from 'react';
import {useStore} from "../../store/useStore";
import GlobalTrans, {GlobalTransIntl} from "../../helper/GlobalTrans";
import {Box, Link, Snackbar} from "@material-ui/core";
import {format} from "date-fns";
import {getPinUrl, saveValueInClipboard} from "../../helper/Helper";
import LoadingDataGrid from "../loadingDataGrid/LoadingDataGrid";
import Token from "../../helper/Token";
import {Alert} from "react-bootstrap";
import {useIntl} from "react-intl";
import Paper from "@material-ui/core/Paper";

const GlobalPinSearch = () => {
    const {state, dispatch} = useStore();
    const intl = useIntl();

    const [searchParameter, setSearchParameter] = useState(state.editForm.search);
    const [mounted, setMounted] = useState(false);
    const [reload, setReload] = React.useState({0: false});

    // Snackbar
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);

    useEffect(() => {
        if (!mounted) {
            setMounted(true);
            dispatch({type: "resetEditForm"});
        } else {
            if (state.editForm.search !== undefined && state.editForm.search && state.editForm.search !== searchParameter) {
                setSearchParameter(state.editForm.search);
                setReload({0: true});
            }
        }
    }, [dispatch, mounted, state.editForm.search, searchParameter]);

    const onClickToken = (params) => {
        saveValueInClipboard(getPinUrl(params));
        setSnackbarOpen(true);
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const getProjectByIRI = (projectIRI) => {
        const project = state.projects.find(project => project['@id'] === projectIRI);

        return (project) ? project.name : '';
    }

    const columns = [
        {
            field: 'token', headerName: GlobalTrans('pin'), width: 200,
            renderCell: (params) => {
                if (params.value) {
                    return <Link onClick={() => onClickToken(params.row)} color={"inherit"}
                                 underline={"none"}>{params.value}</Link>
                }
            }
        },
        {
            field: 'status', headerName: GlobalTrans('status'), width: 120,
            valueGetter: (params) => {
                if (params.value) {
                    return params.value.name;
                }
            }
        },
        {
            field: 'procedureObject', headerName: GlobalTrans('assessment'), width: 150,
            valueGetter: (params) => {
                if (params.value) {
                    return params.value.name;
                }
            }
        },
        {
            field: 'project', headerName: GlobalTrans('project'), width: 140,
            valueGetter: (params) => {
                if (params.value) {
                    return getProjectByIRI(params.value);
                }
            }
        },
        {field: 'firstName', headerName: GlobalTrans('first_name'), width: 140},
        {field: 'lastName', headerName: GlobalTrans('last_name'), width: 140},
        {
            field: 'language', headerName: GlobalTrans('language'), width: 130,
            valueGetter: (params) => (params.value?.name) ? params.value.name : GlobalTransIntl('translation_default', intl),
        },
        {
            field: 'normgroup', headerName: GlobalTrans('normgroup'), width: 140,
            valueGetter: (params) => {
                if (params.value) {
                    return params.value.name;
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'createdAt',
            headerName: GlobalTrans('created'),
            type: 'date',
            width: 150,
            valueGetter: (params) => {
                if (params.value) {
                    return format(new Date(params.value), 'dd.MM.yyyy HH:mm');
                } else {
                    return '-';
                }
            }
        },
        {
            field: 'finishedAt',
            headerName: GlobalTrans('finished'),
            type: 'date',
            width: 150,
            valueGetter: (params) => {
                if (params.value) {
                    return format(new Date(params.value), 'dd.MM.yyyy HH:mm');
                } else {
                    return '-';
                }
            }
        },
        {field: 'comment', headerName: GlobalTrans('comment'), width: 150},
        {
            field: 'pinMails', headerName: GlobalTrans('receiver'), width: 160,
            valueGetter: (params) => {
                if (params.value && params.value.length) {
                    return params.value.map((item) => item.email);
                } else {
                    return '-';
                }
            },
            renderCell: (params) => {
                if (params.value !== '-') {
                    return (
                        <span title={params.value}>
                            {params.value.map((item, key) => {
                                return ((key > 0) ? ',' : '') + item
                            })}
                        </span>
                    )
                }
            }
        },
        {
            field: 'threeSixtyRoleLabel', headerName: GlobalTrans('role'), width: 150,
            valueGetter: (params) => {
                if (params.value && params.value.length) {
                    return params.value;
                } else {
                    return '-';
                }
            }
        }
    ];

    return (
        <Paper>
            <Box p={2}>
                <h1>Global Pin Search</h1>
            </Box>
            <LoadingDataGrid
                apiUrl={'pins'}
                columns={columns}
                dataGridFilter={
                    [
                        {name: 'global', value: searchParameter},
                    ]
                }
                deleteAction={!Token.hasRole(state.tokenData, 'ROLE_PROJECTADMIN')}
                reload={reload}
            />
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} variant="success" sx={{width: '100%'}}>
                    {GlobalTransIntl('clipboard_saved', intl)}
                </Alert>
            </Snackbar>
        </Paper>
    );
};

export default GlobalPinSearch;